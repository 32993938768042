import _is from "./is";
import _to from "./to";
import _key from "./key";
import _owner from "./owner";
import _split from "./split";
import _client from "./client";
import _identity from "./identity";
var exports = {};
var is = _is,
    to = _to,
    key = _key,
    owner = _owner,
    split = _split,
    client = _client,
    identity = _identity,
    DEBUG = strip((client ? (owner.location.search.match(/debug=(.*?)(&|$)/) || [])[1] : key("process.env.DEBUG")(owner)) || ""),
    whitelist = DEBUG.split(",").map(split("/"));

exports = function deb(ns) {
  return DEBUG == "*" || whitelist.some(matches(ns)) ? out : identity;

  function out(d) {
    if (!owner.console || !console.log.apply) return d;
    is.arr(arguments[2]) && (arguments[2] = arguments[2].length);
    var args = to.arr(arguments),
        prefix = "[deb][" + new Date().toISOString() + "]" + ns;
    args.unshift(prefix.grey ? prefix.grey : prefix);
    return console.log.apply(console, args), d;
  }
};

function matches(ns) {
  ns = strip(ns).split("/");
  return function (arr) {
    return arr.length == 1 ? arr[0] == ns[0] : arr.length == 2 ? arr[0] == ns[0] && arr[1] == ns[1] : false;
  };
}

function strip(str) {
  return str.replace(/(\[|\])/g, "");
}

export default exports;